<template>
  <v-app-bar flat style="background-color: #f7f7f7" app clipped-right>
    <v-app-bar-nav-icon @click="showLeftBar">
      <v-img
        v-show="!showLeftBarFlag"
        min-height="21px"
        max-height="21px"
        min-width="25px"
        max-width="25px"
        src="@/assets/icon_menu@2x.png"
      ></v-img>
      <v-img
        v-show="showLeftBarFlag"
        min-height="21px"
        max-height="21px"
        min-width="25px"
        max-width="25px"
        src="@/assets/icon_menu_1@2x.png"
      ></v-img>
    </v-app-bar-nav-icon>
    <span style="font-size: 20px" class="font-weight-bold">{{
      $route.meta.title
    }}</span>
    <v-spacer />
    <v-menu left min-width="600px" offset-y>
      <template #activator="{ on, attrs }">
        <div class="rounded-lg mr-4 msg" style="background-color: #fff">
          <span v-show="msgData.length" class="error--text brage">&bull;</span>
          <v-btn color="black" icon v-bind="attrs" v-on="on">
            <v-icon>mdi-bell</v-icon>
          </v-btn>
        </div>
      </template>
      <v-list>
        <div class="info--text ml-3">你有{{ total }}条消息未读</div>
        <v-list-item
          v-for="item in msgData"
          :key="item.id"
          @click="userReadMsg(item)"
        >
          <v-list-item-icon
            style="background-color: #a6beee"
            class="rounded-circle pa-2"
          >
            <v-badge color="error" dot>
              <v-icon color="#F1F5FC">mdi-bell</v-icon>
            </v-badge>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.content }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.createdTime }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mx-6 mtb-2"></v-divider>
        <div
          style="cursor: pointer"
          class="primary--text text-center mt-2"
          @click="routeMsg"
        >
          查看所有消息
        </div>
      </v-list>
    </v-menu>
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <div
          style="width: 200px; background-color: #fff"
          class="rounded-pill d-flex align-center justify-space-between px-3"
        >
          <v-avatar size="35">
            <img
              v-if="userInfo.avatar"
              alt="user"
              :src="commonUrl + userInfo.avatar"
            />
            <v-icon v-else>mdi-account</v-icon>
          </v-avatar>
          <div class="">{{ userInfo.userName }}</div>
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon> mdi-chevron-down</v-icon>
          </v-btn>
        </div>
      </template>
      <template #default>
        <v-card flat>
          <v-card-text>
            <div
              v-for="(item, index) in userArr"
              :key="index"
              class="d-flex py-3 itemHover rounded-lg"
              @click="userCommand(index)"
            >
              <v-icon class="ml-2">{{ item.icon }}</v-icon>
              <v-list-item-title
                class="ml-3"
                v-text="item.text"
              ></v-list-item-title>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </v-menu>
    <k-crud-dialog
      v-model="showPwd"
      title="修改密码"
      persistent
      max-width="500px"
      @close="dialogClose"
      @save="save"
    >
      <template #dialogContent>
        <v-form ref="form">
          <k-text-input
            v-model="formData.oldPassword"
            label="原密码"
            placeholder="请输入原密码"
            hint="密码包含大小写英文字母，数字，符号"
            type="password"
            :rules="[globalRules.empty]"
            prepend-inner-icon="mdi-onepassword"
          />
          <k-text-input
            v-model="formData.newPassword"
            label="新密码"
            placeholder="请输入新密码"
            hint="密码包含大小写英文字母，数字，符号"
            type="password"
            :rules="[
              globalRules.empty,
              globalRules.likeTest(formData.newPassword, 'confirmPassword', [
                { confirmPassword: formData.confirmPassword },
                { confirmPassword: formData.confirmPassword },
              ]),
            ]"
            prepend-inner-icon="mdi-onepassword"
          />
          <k-text-input
            v-model="formData.confirmPassword"
            label="确认新密码"
            placeholder="请输入再次确认新密码"
            hint="密码包含大小写英文字母，数字，符号"
            type="password"
            :rules="[
              globalRules.empty,
              globalRules.likeTest(formData.confirmPassword, 'newPassword', [
                { newPassword: formData.newPassword },
                { newPassword: formData.newPassword },
              ]),
            ]"
            prepend-inner-icon="mdi-onepassword"
          />
        </v-form>
      </template>
    </k-crud-dialog>
  </v-app-bar>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import encryption from '@/utils/encryption';
import httpConfig from '@/config/http.config';

export default {
  name: 'AppBar',
  data() {
    return {
      commonUrl: httpConfig.imgUrl,
      showLeftBarFlag: false,
      userArr: [
        { icon: 'mdi-lock', text: '修改密码' },
        { icon: 'mdi-power', text: '退出登录' },
      ],
      showPwd: false,
      formData: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.loginStore.account,
      total: (state) => state.messageStore.unreadCount,
      msgData: (state) => state.messageStore.msgList,
    }),
  },
  mounted() {
    this.getMsg();
  },
  methods: {
    ...mapActions(['logout', 'getMsg', 'readMsg']),
    showLeftBar() {
      this.showLeftBarFlag = !this.showLeftBarFlag;
      this.$emit('showLeftBar', this.showLeftBarFlag);
    },
    dialogClose() {
      this.showPwd = false;
    },
    routeMsg() {
      this.$router.push({ path: '/home/mine/msg' });
    },
    getPwd(data, key) {
      if (data.iv.useLastPwd) {
        return encryption.AES.decrypt(data[key], data.iv.iv);
      } else {
        return data[key];
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        let ms = new Date().getTime();
        const data = { ...this.formData };
        data.iv = {};
        const query = {
          iv: {},
        };
        const oldPassword = this.getPwd(data, 'oldPassword');
        const newPassword = this.getPwd(data, 'newPassword');
        const confirmPassword = this.getPwd(data, 'confirmPassword');
        query.timestamp = ms;
        query.oldPassword = encryption.AES.encrypt(oldPassword, ms);
        query.newPassword = encryption.AES.encrypt(newPassword, ms);
        query.confirmPassword = encryption.AES.encrypt(confirmPassword, ms);
        this.$api.system.account
          .changePwd(query)
          .then(({ code, message }) => {
            // eslint-disable-next-line no-debugger
            debugger;
            if (code === 200) {
              this.$alert.success(message);
              this.$refs.form.reset();
              this.showPwd = false;
              this.logout();
            }
          })
          .catch(() => {
            // eslint-disable-next-line no-debugger
            debugger;
            this.$refs.form.reset();
          });
      }
    },
    clickLogout() {
      const { logout } = this;
      // const { logout, $router, $util } = this;
      logout({});
    },
    userReadMsg(item) {
      this.readMsg(item);
    },
    userCommand(index) {
      if (index) {
        this.clickLogout();
      } else {
        this.changePwd();
      }
    },
    changePwd() {
      this.showPwd = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.itemHover:hover {
  cursor: pointer;
  background-color: rgba(#ebf1fc, 0.8);
}
//::v-deep .v-list-item--link:before {
//
//}
//::v-deep .theme--light.v-list-item:hover::before {
//  background-color: rgba(#ebf1fc, 0.8);
//  opacity: 1;
//}
.msg {
  position: relative;

  .brage {
    position: absolute;
    display: inline;
    top: -10px;
    right: 5px;
    font-size: 24px;
  }
}
</style>
