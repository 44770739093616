var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticStyle:{"background-color":"#f7f7f7"},attrs:{"flat":"","app":"","clipped-right":""}},[_c('v-app-bar-nav-icon',{on:{"click":_vm.showLeftBar}},[_c('v-img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showLeftBarFlag),expression:"!showLeftBarFlag"}],attrs:{"min-height":"21px","max-height":"21px","min-width":"25px","max-width":"25px","src":require("@/assets/icon_menu@2x.png")}}),_c('v-img',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLeftBarFlag),expression:"showLeftBarFlag"}],attrs:{"min-height":"21px","max-height":"21px","min-width":"25px","max-width":"25px","src":require("@/assets/icon_menu_1@2x.png")}})],1),_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(_vm.$route.meta.title))]),_c('v-spacer'),_c('v-menu',{attrs:{"left":"","min-width":"600px","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"rounded-lg mr-4 msg",staticStyle:{"background-color":"#fff"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.msgData.length),expression:"msgData.length"}],staticClass:"error--text brage"},[_vm._v("•")]),_c('v-btn',_vm._g(_vm._b({attrs:{"color":"black","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-bell")])],1)],1)]}}])},[_c('v-list',[_c('div',{staticClass:"info--text ml-3"},[_vm._v("你有"+_vm._s(_vm.total)+"条消息未读")]),_vm._l((_vm.msgData),function(item){return _c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.userReadMsg(item)}}},[_c('v-list-item-icon',{staticClass:"rounded-circle pa-2",staticStyle:{"background-color":"#a6beee"}},[_c('v-badge',{attrs:{"color":"error","dot":""}},[_c('v-icon',{attrs:{"color":"#F1F5FC"}},[_vm._v("mdi-bell")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.content))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.createdTime))])],1)],1)}),_c('v-divider',{staticClass:"mx-6 mtb-2"}),_c('div',{staticClass:"primary--text text-center mt-2",staticStyle:{"cursor":"pointer"},on:{"click":_vm.routeMsg}},[_vm._v(" 查看所有消息 ")])],2)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"rounded-pill d-flex align-center justify-space-between px-3",staticStyle:{"width":"200px","background-color":"#fff"}},[_c('v-avatar',{attrs:{"size":"35"}},[(_vm.userInfo.avatar)?_c('img',{attrs:{"alt":"user","src":_vm.commonUrl + _vm.userInfo.avatar}}):_c('v-icon',[_vm._v("mdi-account")])],1),_c('div',{},[_vm._v(_vm._s(_vm.userInfo.userName))]),_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-chevron-down")])],1)],1)]}},{key:"default",fn:function(){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',_vm._l((_vm.userArr),function(item,index){return _c('div',{key:index,staticClass:"d-flex py-3 itemHover rounded-lg",on:{"click":function($event){return _vm.userCommand(index)}}},[_c('v-icon',{staticClass:"ml-2"},[_vm._v(_vm._s(item.icon))]),_c('v-list-item-title',{staticClass:"ml-3",domProps:{"textContent":_vm._s(item.text)}})],1)}),0)],1)]},proxy:true}])}),_c('k-crud-dialog',{attrs:{"title":"修改密码","persistent":"","max-width":"500px"},on:{"close":_vm.dialogClose,"save":_vm.save},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c('v-form',{ref:"form"},[_c('k-text-input',{attrs:{"label":"原密码","placeholder":"请输入原密码","hint":"密码包含大小写英文字母，数字，符号","type":"password","rules":[_vm.globalRules.empty],"prepend-inner-icon":"mdi-onepassword"},model:{value:(_vm.formData.oldPassword),callback:function ($$v) {_vm.$set(_vm.formData, "oldPassword", $$v)},expression:"formData.oldPassword"}}),_c('k-text-input',{attrs:{"label":"新密码","placeholder":"请输入新密码","hint":"密码包含大小写英文字母，数字，符号","type":"password","rules":[
            _vm.globalRules.empty,
            _vm.globalRules.likeTest(_vm.formData.newPassword, 'confirmPassword', [
              { confirmPassword: _vm.formData.confirmPassword },
              { confirmPassword: _vm.formData.confirmPassword } ]) ],"prepend-inner-icon":"mdi-onepassword"},model:{value:(_vm.formData.newPassword),callback:function ($$v) {_vm.$set(_vm.formData, "newPassword", $$v)},expression:"formData.newPassword"}}),_c('k-text-input',{attrs:{"label":"确认新密码","placeholder":"请输入再次确认新密码","hint":"密码包含大小写英文字母，数字，符号","type":"password","rules":[
            _vm.globalRules.empty,
            _vm.globalRules.likeTest(_vm.formData.confirmPassword, 'newPassword', [
              { newPassword: _vm.formData.newPassword },
              { newPassword: _vm.formData.newPassword } ]) ],"prepend-inner-icon":"mdi-onepassword"},model:{value:(_vm.formData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.formData, "confirmPassword", $$v)},expression:"formData.confirmPassword"}})],1)]},proxy:true}]),model:{value:(_vm.showPwd),callback:function ($$v) {_vm.showPwd=$$v},expression:"showPwd"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }